html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--background-default);
  color: var(--text-primary);
}

.main-container {
  flex: 1;
}
